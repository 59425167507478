import tw, { styled } from 'twin.macro';
import { BREAKPOINTS } from '../../constants';

type TopImageProps = {
  isHomepage: boolean;
};

export const TopImage = styled.section<TopImageProps>(({ isHomepage })  => {
  const out = [
    {
      picture: {
        img: {
          width: '100%',
        },
      },
      marginBottom: -28,
      [`@media screen and (max-width: ${BREAKPOINTS.md}px)`]: {
        marginBottom: isHomepage ? -20 : 90,
      },
      [`@media screen and (max-width: ${BREAKPOINTS.sm}px)`]: {
        marginBottom: isHomepage ? -44 : 90,
      },
      [`@media screen and (max-width: ${BREAKPOINTS.xs}px)`]: {
        marginBottom: isHomepage ? -44 : -30,
      },
    },
  ];

  return out;
});
